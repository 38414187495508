body {
  font-family: Lato, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Header */
#header {
  padding-top: 2rem;
  padding-bottom: 0.5rem;
}

/* Main */
#start {
  margin-bottom: 1rem;
}

.row {
  margin-left: 0px;
  margin-right: 0px;
  column-gap: 2rem;
}

div[class^="col-"] {
  padding-left: 0px;
  padding-right: 0px;
}

/* Footer */
#footer {
  padding-top: 2rem;
}

/* Contact */
#github-logo {
  max-width: 5rem;
  padding-bottom: 1rem;
}

@media only screen and (max-width: 576px) {
  #header {
    padding-top: 1rem;
    padding-bottom: 0;
  }
  #footer {
    padding-top: 1rem;
  }
}

@media only screen and (max-width: 1200px) {
  #header {
    padding-top: 1.5rem;
    padding-bottom: 0;
  }
  #footer {
    padding-top: 1.5rem;
  }
  [class*="col-"] {
    width: 100%;
  }
}
